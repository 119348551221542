@import "../../../velotooler-app";

.vui-breadcrumb {
  flex-grow: 2;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: left;
  padding-left: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;

  .vui-breadcrumb__prev {
    svg path {
      @include transition-short(stroke);
    }

    &:hover {
      svg path {
        stroke: $vui-label-color;
      }
    }
  }

  .vui-breadcrumb__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $vui-label-color;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .vui-breadcrumb__item-dot {
      padding: 0 0.75rem;

      svg {
        @include size-rect(0.75rem);
      }
    }

    &:last-child {
      color: $vui-input-label-color;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .vui-breadcrumb {
    padding-left: 0;

    .vui-breadcrumb__item {
      &:not(:last-child) {
        display: none;
      }

      .vui-breadcrumb__item-dot {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-breadcrumb {
    padding-left: 1.25rem;
    font-size: 0.875rem;
    line-height: 0.875rem;

    .vui-breadcrumb__item {
      &:not(:last-child) {
        display: none;
      }

      .vui-breadcrumb__item-dot {
        display: none;
      }
    }
  }
}