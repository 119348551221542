$color-primary-l7: rgb(95, 95, 95);
$color-primary-l6: rgb(85, 85, 85);
$color-primary-l5: rgb(75, 75, 75);
$color-primary-l4: rgb(65, 65, 65);
$color-primary-l3: rgb(55, 55, 55);
$color-primary-l2: rgb(45, 45, 45);
$color-primary-l1: rgb(40, 40, 40);
$color-primary: rgb(35, 35, 35);
$color-primary-d1: rgb(25, 25, 25);
$color-primary-d2: rgb(20, 20, 20);
$color-primary-d3: rgb(15, 15, 15);
$color-primary-d4: rgb(05, 05, 05);

$color-primary-o9: rgba(35, 35, 35, .9);
$color-primary-o8: rgba(35, 35, 35, .8);
$color-primary-o7: rgba(35, 35, 35, .7);
$color-primary-o6: rgba(35, 35, 35, .6);
$color-primary-o5: rgba(35, 35, 35, .5);
$color-primary-o4: rgba(35, 35, 35, .4);
$color-primary-o3: rgba(35, 35, 35, .3);
$color-primary-o2: rgba(35, 35, 35, .2);
$color-primary-o1: rgba(35, 35, 35, .1);

$color-primary-d1-o9: rgba(25, 25, 25, .9);
$color-primary-l5-o3: rgba(75, 75, 75, .3);

$header-background: rgba(0, 0, 0, 0.5);
$container-background: #10100F;
$navbar-button-background: rgba(0, 0, 0, 0);
$navbar-button-background-hover: rgba(200, 200, 200, 0.12);
$vt-color-primary: #ffec92;
$vt-color-primary-hue-1: #FFFFCB;
$color-main: #ffec92;
$footer-color-link: #000000;
$footer-color-link-hover: #555;
$done-button-text-color: #51c57e;
$color-green: #51c57e;
$warning-button-text-color: #eabe6a;
$form-background: $color-primary;
$input-default-color: #F5F5F5;
/*$input-default-color: #FFEB3B;*/
$input-error-color: #EF5350;
$input-remove-color: #EF5350;
$color-red: #EB5757;
$input-active-remove-color: #E53935;
$remove-button-color: rgba(199, 57, 57, 0.65);
$input-active-color: $vt-color-primary-hue-1;
$input-disabled-color: #c5c1c1;
$no-focusing-button: $navbar-button-background-hover;
$label-color: #aaa;
$color-white: #ffffff;
$color-dark: #000000;
$color-gray: #4F4F4F;
$color-blue: #00aae5;
$color-light-blue: #00ccc8;
$separator-color: #222;
$remove-icon-color: #ccc;
$text-main-color: #888888;
$text-hover-color: $color-white;
$color-brown: #423c37;
$transparent: #00000000;

$z-index-tooltip: 100 !default;
$tooltip-fontsize-sm: rem(1.4);
$layout-breakpoint-sm: 960px !default;
$tooltip-fontsize-lg: rem(1);
$tooltip-height-sm: rem(3.2);
$tooltip-lr-padding-sm: rem(1.6);
$tooltip-height-lg: rem(2.2);
$tooltip-lr-padding-lg: rem(0.8);
$swift-ease-out-duration: 0.4s !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;
$swift-ease-in-duration: 0.3s !default;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;
$width-weekday-button: calc(100% / 7);
$width-experience-search-button: calc(100% / 3);
$bike-colors-panel-height: 22px;
$container-width-1200: 1200px;
$smile-img: url("/img/chat/1.png");
$sidebar-size: 64px;
$mobile-width: 470px;
$md-width: 992px;
$not-mobile-width: 471px;

@mixin common-content-padding-top() {
  padding-top: 7%;
}

@mixin bootstrap-container-paddings() {
  padding-left: 15px;
  padding-right: 15px;
}

@mixin floatClearAfter() {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin floatClearBefore() {
  &:before {
    content: "";
    display: block;
    clear: both;
  }
}


$vui-color-dark--hover: $color-primary;

$vui-color-primary: #FFF400;
$vui-color-primary--hover: #FFE600;

$vui-dark-text-color: $color-dark;
$vui-dark-text-color--hover: #0000004D;
$vui-text-placeholder-color: #b3b3b3;
$vui-light-text-color: $color-white;
$vui-light-text-color--hover: #c8c8c8;
$vui-footer-color-link: #161616;

$vui-dark-input-color: #F5F5F5;
$vui-dark-input-placeholder-color: rgba(0, 0, 0, 1);
$vui-input-error-color: #B00000;
$vui-input-label-color: #000000;
$vui-input-placeholder-color: #C8C8C8;
$vui-label-color: #808080;

$vui-header-height: 84px;
$vui-header-height-sm: 54px;
$vui-portal-header-height-sm: 60px;
$vui-portal-header-with-bsk-height-sm: 98px;
$vui-main-menu-color: #EDEDED;
$vui-content-background: #F7F7F7;

$vui-transition-short-time: 250ms;
$vui-transition-middle-time: 400ms;

$vui-screen-dv: only screen and (max-width: 991px); // mobile and tablet

@mixin size-rect($size) {
  width: $size;
  height: $size;
}

@mixin size-100() {
  @include size-rect(100%);
}

@mixin margin-x($margin) {
  margin-left: $margin;
  margin-right: $margin;
}

@mixin margin-y($margin) {
  margin-top: $margin;
  margin-bottom: $margin;
}

@mixin background-cover() {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@mixin transition($type, $time) {
  transition: $type $time ease-in-out;
}

@mixin transition-middle($type) {
  @include transition($type, $vui-transition-middle-time);
}

@mixin transition-all-middle() {
  @include transition-middle(all);
}

@mixin transition-short($type) {
  @include transition($type, $vui-transition-short-time);
}

@mixin transition-all-short() {
  @include transition-short(all);
}

@mixin transition-color-short() {
  @include transition-short(color);
}

@mixin scrollbar() {
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: $vui-main-menu-color;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: $vui-main-menu-color;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #999999;
  }
}

@mixin no-data-font() {
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 2.75rem;
  text-align: left;
  color: $vui-label-color;

  @media only screen and (max-width: 767px) {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
  }
}
