@import "../../../../velotooler-app";

$default-diff: 15;
$default-width: 100%;
$default-min: 10%;
$default-height: 322px;

$card-items: (1: 4.2, 2: 3.3, 3: 2.3, 4: 1.2, 5: 0);

@mixin item-top($n) {
  top: percentage(calc(((15 - $n) * $n + $n - 1) / 100));

  @media only screen and (max-width: 500px) {
    top: percentage(calc(((15 - $n) * $n) / 100));
  }
}

.landing-page-carousel-item {
  @include transition-all-middle();
  border-radius: 1.25rem;
  margin: 0 auto;
  box-shadow: 0 5px 10px rgba(34, 34, 34, 0.1);
  background-color: #f6f6f6;

  img {
    @include transition-all-middle();
  }

  @each $i, $n in $card-items {
    &:nth-child(#{$i}) {
      @include item-top(5 - $i);
      width: $default-width - $default-min * $n;

      img {
        opacity: 0;
      }
    }
  }

  &:nth-child(5), &.transformPrev {
    border: 6px solid $color-dark;
    opacity: 1;

    img {
      opacity: 1 !important;
    }
  }

  .carousel-screen {
    @include size-100();
    border-radius: 0.875rem;
  }
}

@media only screen and (max-width: 767px) {
  .landing-page-carousel-item {
    &:nth-child(5), &.transformPrev {
      border: 3px solid $color-dark;
    }

    .carousel-screen {
      border-radius: 1.25rem;
    }
  }
}
