@import "../../../velotooler-app";

.vui-change-payment-source-model {
  .vui-modal {
    max-width: 440px;
    width: 100%;
  }

  .vui-change-payment-source-model__body {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .payment-source__subtitle {
      font-size: 1.25rem;
      font-weight: 600;
      text-align: left;
    }

    .payment-source__list {
      .vui-radio-button {
        .payment__icon-content {
          height: 2.5rem;

          .ps__icon {
            height: 2.5rem;
          }
        }

        .vui-radio-button__label,
        .vui-radio-button__label .payment__last4 {
          display: flex;
          flex-direction: row;
          column-gap: 0.5rem;
          align-items: center;
        }
      }
    }

    .payment-source__add-card {
      font-size: 1rem;
      font-weight: 600;
      text-align: left;
      text-decoration: underline;
      cursor: pointer;
      @include transition-short(color);

      &:hover {
        color: $vui-label-color;
      }
    }
  }

  .vui-change-payment-source-model__actions {
    column-gap: 1.5rem;
  }
}
